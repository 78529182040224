import { KultunautEvent } from "../../../gogoo-common/content/Kultunaut";

export type KultunautEventWithAVGDate = (KultunautEvent & { avgUnixTime: number })

//Converts the time strings into HH:MM
function formatTime(timeStr: string) {
    // Remove "kl." and any spaces
    timeStr = timeStr.replace("kl.", "").trim();

    // Split on any hyphen or dash to get the start time
    let startTime = timeStr.split('-')[0].trim();

    // Replace any "." with ":"
    startTime = startTime.replace(".", ":");

    // If there's no ":", it's a whole hour, so append ":00"
    if (!startTime.includes(":")) {
        startTime += ":00";
    }

    // If there's only one digit for the hour, prepend a "0"
    if (startTime.length === 4) {
        startTime = "0" + startTime;
    }

    return startTime;
}


export const KultunautDateToNodeDate = (date: string, time?: string): Date => {
    if (!date) return new Date()
    const dateArray = date.split('-')
    if (isNaN(Number(dateArray[0])) || isNaN(Number(dateArray[1])) || isNaN(Number(dateArray[2]))) {
        return new Date()
    }
    const nodeDate = new Date()
    nodeDate.setDate(Number(dateArray[0]))
    nodeDate.setMonth(Number(dateArray[1]) - 1)
    nodeDate.setFullYear(Number(dateArray[2]))

    if (time) {
        const formattedTime = formatTime(time)
        if (formattedTime.includes(':')) {
            const timeArray = formattedTime.split(':')
            nodeDate.setMinutes(Number(timeArray[1]))
            nodeDate.setHours(Number(timeArray[0]))
        } else {
            nodeDate.setMinutes(59)
            nodeDate.setHours(23)
        }
    }
    return nodeDate
}


export const nodeDateToKultunautDate = (date: Date): string => {
    return date.getDate() + '-' + (date.getMonth() + 1) + '-' + date.getFullYear()
}



export const kultuanutDateToNumber = (date: string, time?: string): number => {
    const d = KultunautDateToNodeDate(date, time)
    return d.getTime()
}


export const sortByEndDate = (events?: KultunautEvent[]): KultunautEvent[] => {
    return events?.sort((a, b) => kultuanutDateToNumber(a.Enddate) - kultuanutDateToNumber(b.Enddate)) || []
}

