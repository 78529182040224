import * as React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { LoadingScreen } from '../../components/atoms/Defaults';
import { Navigation } from '../../components/organisms/Navigation';
import Layout from '../../components/templates/Layout';
import { Loading, LoadingState } from '../../globals/states';
import apiHooksV2 from '../../globals/api-hooks-v2';
import { BreadCrumb } from '../../components/molecules/BreadCrumb';
import { Banner, CreateBanner, VenueBanner } from 'gogoo-common/content/Banner';
import { BannerEditor } from '../../components/templates/EditBanner';
import { ErrorScreenTemplate } from '../../components/templates/ErrorScreen';
import { KultunautVenue } from 'gogoo-common/content/Kultunaut';


export const VenueBannerPage: React.FC<{ create?: boolean }> = ({ create }) => {
  const navigation = useNavigate()
  const { venueId, bannerId } = useParams<{ venueId: string, bannerId?: string }>()
  const [venue, setVenue] = React.useState<KultunautVenue>()
  const [loading, setLoading] = React.useState<LoadingState>()
  const [banner, setBanner] = React.useState<Banner>()

  const init = React.useCallback(async () => {
    if(!venueId) return
    setLoading({ loading: Loading.LOADING })
    const result = await apiHooksV2.getVenueDashboard(Number(venueId))
    if (result) {
      setVenue(result.venue)
      if (create) {
        setLoading({ loading: Loading.COMPLETE })
      } else {
        const banner = result.banners?.find((i) => i.id === bannerId)

        if (banner) {
          setBanner(banner)
          setLoading({ loading: Loading.COMPLETE })
        } else {
          setLoading({ loading: Loading.ERROR, errorMessage: "Kunne ikke finde banner med id: " + bannerId })
        }
      }
    } else {
      setLoading({ loading: Loading.ERROR, errorMessage: "Kunne ikke finde udbyderen med id: " + venueId })
    }
  }, [venueId])

  React.useEffect(() => { init() }, [init])

  if (!loading || loading.loading === Loading.LOADING) return <LoadingScreen />
  if (loading.loading === Loading.ERROR) return <ErrorScreenTemplate>Error: {loading.errorMessage}</ErrorScreenTemplate>
  if (!venueId || !venue) return <ErrorScreenTemplate><p>Error: Contact IT Admin</p></ErrorScreenTemplate>

  const onUpdate = async (props: Omit<CreateBanner, 'type'>) => {
    setLoading({ loading: Loading.LOADING })
    if (create) {
      const orgBanner: Omit<VenueBanner, 'id'> = {
        ...props, type: 'venue',
        venueId: Number(venueId)
      }
      const result = await apiHooksV2.createBanner(orgBanner)
      setBanner(result)
      navigation("/venues/" + venueId + "/banners/" + result.id)
    }
    else if (banner) setBanner(await apiHooksV2.updateBanner({ ...banner, ...props }))
    setLoading({ loading: Loading.COMPLETE })

  }

  return (
    <Layout.Body>
      <Navigation title={venue.Name} />
      <Layout.Container>
        <Layout.HorizontalFlex style={{ justifyContent: 'space-between', marginLeft: 14, marginRight: 14, alignItems: 'center' }}>
          <BreadCrumb links={[
            { label: "Udbyder", url: "/venues" },
            { label: venue?.Name ||'', url: "/venues/" + venue.Id },
            { label: "Banners", url: "/venues/" + venue.Id },
            { label: banner?.title || "Ny banner", url: "/venues/" + venue.Id + "/banners/" + (bannerId || "create") },
          ]} />
        </Layout.HorizontalFlex>
      </Layout.Container>
      <Layout.Container>
        <BannerEditor banner={banner} onSubmit={onUpdate} />
      </Layout.Container>
    </Layout.Body>
  )
}