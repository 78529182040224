import React, { useRef, useState } from "react";
import Input from "./Input";
import './HTMLInput.css'
import { colors } from "../../globals/colors";


const buttonStyles = { margin: 0, paddingLeft: "10px", paddingRight: "10px", backgroundColor: colors.white, color: "#000", border: '1px solid #000', borderRadius: 0 }

const LinkButton: React.FC<{ onSubmit(_: string): void }> = ({ onSubmit }) => {
  const [showInput, setShowInput] = useState(false);
  const [url, setUrl] = useState("");

  const handleSubmit = () => {
    onSubmit(url);
    setShowInput(false);
    setUrl("");
  };


  return (
    <>
      <button style={buttonStyles} onClick={() => setShowInput(!showInput)}>Link</button>
      <div style={{ position: "relative" }}>
        {showInput && (
          <div style={{ position: "absolute", top: "100%", left: -50, backgroundColor: "#fff", padding: 6, border: "1px solid #ccc", borderRadius: 4, zIndex: 1, marginTop: 4 }}>
            <input
              type="text"
              value={url}
              onChange={(e) => setUrl(e.target.value)}
              placeholder="Indsæt URL"
              style={{ marginRight: 6, backgroundColor: "#fff", color: "#000", border: "1px solid #ccc", borderRadius: 4 }}
            />
            <button style={buttonStyles} onClick={handleSubmit}>Tilføj link</button>
          </div>
        )}
      </div>
    </>
  );
};


interface RichTextEditorProps {
  value?: string;
  onChange: (html: string) => void;
}

const RichTextEditor: React.FC<RichTextEditorProps> = ({ value: initialValue = "", onChange }) => {
  const editorRef = useRef<HTMLDivElement>(null);

  // Effect to initialize the editor content when the value prop changes
  React.useEffect(() => {
    if (editorRef.current && editorRef.current.innerHTML !== initialValue) {
      editorRef.current.innerHTML = initialValue;
    }
  }, [initialValue]);

  // Handle input changes and send the updated HTML to onChange
  const handleInput = () => {
    if (editorRef.current) {
      onChange(editorRef.current.innerHTML);
    }
  };

  // Apply formatting commands
  const formatText = (command: string, value?: string) => {
    document.execCommand(command, false, value || "");
    handleInput(); // Trigger onChange after formatting
    editorRef.current?.focus();
  };


  return (
    <div style={{ border: "1px solid #ccc", borderRadius: 4, padding: 6, color: '#000' }}>
      <i style={{ color: "gray" }}><b>Bemærk:</b> Tekstredigeringsværktøjet fungerer bedst i Chrome-browseren. Nogle funktioner virker muligvis ikke i Firefox og Safari.</i>
      <div style={{ display: "flex", marginBottom: 6, marginTop: 6, flexDirection: 'row', gap: 6, flexWrap: 'wrap' }}>
        <button style={buttonStyles} onClick={() => formatText("formatBlock", "H1")}>Overskrift 1</button>
        <button style={buttonStyles} onClick={() => formatText("formatBlock", "H2")}>Overskrift 2</button>
        <button style={buttonStyles} onClick={() => formatText("formatBlock", "p")}>Tekst</button>
        <button style={buttonStyles} onClick={() => formatText("bold")}>Fed skrift</button>
        <button style={buttonStyles} onClick={() => formatText("justifyLeft")}>Venstre</button>
        <button style={buttonStyles} onClick={() => formatText("justifyCenter")}>Center</button>
        <button style={buttonStyles} onClick={() => formatText("justifyRight")}>Højre</button>
        <LinkButton onSubmit={(val) => formatText("createLink", val)} />
        <button style={buttonStyles} onClick={() => formatText("removeFormat")}>Fjern formattering</button>
        <Input.ImageUpload onChange={(url) => formatText("insertImage", url)} />
      </div>
      <div
        className="html-editor"
        ref={editorRef}
        contentEditable
        onInput={handleInput} // Trigger onChange on input
        style={{
          minHeight: "200px",
          border: "1px solid #ddd",
          padding: 6,
          outline: "none",
          borderRadius: 4,
          color: '#000'
        }}
      ></div>
    </div>
  );
};



export default RichTextEditor;