import * as React from 'react';
import Input from '../../components/atoms/Input';
import Layout from '../../components/templates/Layout';
import { FeedBannerItem } from '../atoms/AppComponents';
import { defaultGridLocations, DrawableMap } from '../molecules/Maps';
import { Banner, CreateBanner, GridLocation, OrganizationBanner } from 'gogoo-common/content/Banner';
import { colors } from '../../globals/colors';

const toIso = (date?: string) => {
    if (!date) return
    let isoDate = new Date(date)
    const offset = isoDate.getTimezoneOffset()
    isoDate = new Date(isoDate.getTime() - (offset * 60 * 1000))
    return isoDate.toISOString().split('T')[0]
}

const toDB = (date: string) => {
    const UCT = (new Date(date))
    return (new Date(UCT)).toISOString()
}

const bannerTypeOptions: { [key: string]: string } = {
    "hero-banner": "Hero banner (Ligger kun øverst i appen)",
    "normal-banner": "Normal banner (Ligger forskellige steder i appen, men ikke øverst)"
}

const bannerTypeKeyValuePairs = Object.keys(bannerTypeOptions).map((key) => ({ key, displayName: bannerTypeOptions[key] || key }))

type EditBannerProps = { banner?: Banner, onSubmit(updated: Omit<CreateBanner, 'type'>): Promise<void> }
export const BannerEditor: React.FC<EditBannerProps> = ({ banner, onSubmit }) => {

    const [title, setTitle] = React.useState(banner?.title)
    const [description, setDescription] = React.useState(banner?.description)
    const [imageUrl, setImageUrl] = React.useState(banner?.image)

    const [startDateString, setStartDateString] = React.useState(banner?.startDate)
    const [endDateString, setEndDateString] = React.useState(banner?.endDate)

    const [gridLocation, setGridLocation] = React.useState(banner?.location || defaultGridLocations)
    const [bannerType, setBannerType] = React.useState<"hero-banner" | "normal-banner">(banner?.bannerType || "normal-banner")

    const [errorMessage, showError] = React.useState<string>()


    const handleSubmission = async (updatedGrid?: GridLocation) => {
        if (!title) return showError("No title")
        if (!description) return showError("No description")
        if (!imageUrl) return showError("Billede ikke valgt")
        if (!startDateString) return showError("Start dato ikke valgt")
        if (!endDateString) return showError("Slut dato ikke valgt")

        await onSubmit({
            title,
            description,
            image: imageUrl,
            published: true,
            startDate: toDB(startDateString),
            endDate: toDB(endDateString),
            location: updatedGrid || gridLocation,
            bannerType
        })
    }

    const handleBannerTypeChange = (val: string | number) => {
        if (val === "hero-banner") setBannerType("hero-banner")
        else setBannerType("normal-banner")
    }

    return (
        <Layout.VerticalFlex style={{ marginBottom: 64 }}>
            <Layout.HorizontalFlex style={{ justifyContent: 'space-between' }}>
                <Layout.VerticalFlex style={{ minWidth: 300, marginRight: 10, width: '100%' }}>
                    <h2>Banner indhold</h2>
                    <div style={{ backgroundColor: colors.white, color: colors.danger }}>{errorMessage}</div>
                    <span style={{ marginBottom: 3 }}>Title</span>
                    <input value={title} onChange={(e) => setTitle(e.target.value)} style={{ marginBottom: 12 }} />
                    <span style={{ marginBottom: 3 }}>Beskrivelse</span>
                    <input value={description} onChange={(e) => setDescription(e.target.value)} style={{ marginBottom: 12 }} />
                    <span style={{ marginBottom: 3 }}>Billede</span>
                    <Input.ImageUpload onChange={setImageUrl} exitingFile={imageUrl} />
                    <b style={{ marginTop: 24 }}>Dato interval</b>
                    <span style={{ marginBottom: 3, marginTop: 12 }}>Start dato</span>
                    <input value={toIso(startDateString)} type='date' onChange={(e) => setStartDateString(e.target.value)} style={{ marginBottom: 12 }} />
                    <span style={{ marginBottom: 3 }}>Slut dato</span>
                    <input value={toIso(endDateString)} type='date' onChange={(e) => setEndDateString(e.target.value)} style={{ marginBottom: 12 }} />
                    <span style={{ marginBottom: 3 }}>Banner type</span>
                    <Input.Select onSelect={(i) => handleBannerTypeChange(i.key)} selected={{ key: bannerType, displayName: bannerTypeOptions[bannerType] }}
                        items={bannerTypeKeyValuePairs} />
                    <Layout.VerticalFlex style={{ marginTop: 32 }}>
                        <FeedBannerItem title={title || ''} image={imageUrl || ''} description={description || ''} />
                    </Layout.VerticalFlex>

                </Layout.VerticalFlex>
                <Layout.VerticalFlex>
                    <h2>Banner preview</h2>
                    <DrawableMap onComplete={(grid) => { setGridLocation(grid); handleSubmission(grid) }} location={gridLocation} />
                    <i style={{ marginTop: 12, opacity: 0.5 }}>Det er kun Gogo Administrator som kan redigere og oprette Banner. Kontakt derfor <a href='mailto:kontakt@gogoo.app'>kontakt@gogoo.app</a> for at ændre indholdet.</i>
                </Layout.VerticalFlex>
            </Layout.HorizontalFlex>

        </Layout.VerticalFlex>
    )
}